import { openApplyAccountDialog, openNewProjectDialog } from '@paid-ui/blocks/global-flyout';
import { errorMessages } from '@paid-ui/forms/apply-account/constants/error-messages';
import { featureManager } from '@paid-ui/models/feature';
import { userManager } from '@paid-ui/models/user';
import { UserGuide, type UserGuideAction } from '@paid-ui/ui';
import { useMemo } from 'react';
import { useSnapshot } from 'valtio/react';

type Props = {
  numberOfProjects: number;
  loading?: boolean;
};

export const DashboardGuide: React.FC<Props> = ({ numberOfProjects, loading }) => {
  const { enableTransactionAccount } = useSnapshot(featureManager);
  const { isAccountRequestPending, isAccountRequestFailed, isAccountReady } =
    useSnapshot(userManager);

  const [disabledAccount, disableAccountReason] = useMemo(() => {
    if (isAccountRequestPending) return [true, errorMessages.requestPending];
    if (isAccountRequestFailed) return [true, errorMessages.requestFailed];
    return [false, ''];
  }, [isAccountRequestPending, isAccountRequestFailed]);

  const actions = useMemo<UserGuideAction[]>(() => {
    return [
      {
        key: 'create-transaction-account',
        title: 'Create a transaction account',
        benefits: [
          'Unlock Paid full feature set',
          'Send and receive fast payments',
          'Save time and money',
        ],
        actionText: 'Create transaction account',
        hidden: !enableTransactionAccount,
        completed: isAccountReady,
        disabled: disabledAccount,
        disableReason: disableAccountReason,
        onClick() {
          openApplyAccountDialog();
        },
      },
      {
        key: 'create-project',
        title: 'Create a project',
        benefits: [
          'Easy contract management',
          'View everything in one place',
          'Real time contract notifications',
        ],
        actionText: 'Create project',
        completed: numberOfProjects > 0,
        disabled: loading,
        onClick() {
          openNewProjectDialog();
        },
      },
    ];
  }, [
    enableTransactionAccount,
    isAccountReady,
    disabledAccount,
    disableAccountReason,
    numberOfProjects,
    loading,
  ]);

  return <UserGuide title="Dashboard guide" actions={actions} loading={loading} />;
};
